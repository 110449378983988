



















































































import { activeCallStore, callsStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { createRawSVGComponent } from '@/components/UI/icons/Factory/index.jsx'
import { move } from '@/utils'
import { ServerCallBuzzParams } from '@tada-team/tdproto-ts'

@Component
export default class ExternalTeamCall extends Vue {
  @Prop({
    type: ServerCallBuzzParams,
    required: true,
  }) readonly call!: ServerCallBuzzParams

  iconSize = 14

  get iconPhone () {
    const icon = 'phone'
    return createRawSVGComponent({
      icon,
      width: this.iconSize,
      height: this.iconSize,
      sizeStorage: 20,
      permanentlyActive: true,
      size: undefined,
    })
  }

  get iconPhoneDown () {
    const icon = 'phone_down'
    return createRawSVGComponent({
      icon,
      width: this.iconSize,
      height: this.iconSize,
      sizeStorage: 20,
      permanentlyActive: true,
      size: undefined,
    })
  }

  get chatDisplayName () {
    let name = this.call.teaminfo.name
    if (this.call.chat.chatType !== 'direct') {
      name += ' — ' + this.call.actor.displayName
    }
    return name
  }

  get iconUrl () {
    const url = this.call.chat.icons.sm?.url || this.call.chat.icons.stub
    return `url('${url}')`
  }

  async redirect () {
    const teamId = this.call.teaminfo.uid
    const chatId = this.call.chat.jid

    callsStore.mutations.setExternalCall(null)
    move(teamId, chatId)

    const call = setInterval(() => {
      if (this.$store.getters.isSocketReady) {
        activeCallStore.actions.answerCall({ jid: chatId, teamId })
        clearInterval(call)
      }
    }, 100)
  }

  async reject () {
    await callsStore.actions.reject({
      teamId: this.call.teaminfo.uid,
      chatId: this.call.chat.jid,
    })
  }
}
