import { render, staticRenderFns } from "./ExternalTeamCall.vue?vue&type=template&id=511f7a3c&scoped=true&"
import script from "./ExternalTeamCall.vue?vue&type=script&lang=ts&"
export * from "./ExternalTeamCall.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511f7a3c",
  null
  
)

export default component.exports
import {QMenu,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QMenu,QBtn})
